/* CARD */
.CardContact {
  background: var(--grey);
  display: block;
  /* padding: 7rem; */
  /* width: 2rem;
    height: 2rem; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.CardContact a {
  padding: 7rem;
  transition: color 1s ease;
}
.CardContact i {
  font-size: 5rem;
}
.CardContact a:hover {
  color: var(--cyan) !important;
}
/* CONTACTS */
.Contact div {
  display: flex;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;
}

.WhatsappMovile {
  display: none !important;
  /* border: 3px solid red; */
}
.WhatsappDesktop {
  /* border: 3px solid blue; */
}
@media screen and (max-width: 1000px) {
  .WhatsappDesktop {
    display: none !important;
    /* border: 3px solid red; */
  }
  .WhatsappMovile {
    display: inline !important;
  }
}
