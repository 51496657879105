.Navbar {
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  color: white;
  /* background: black; */
}
.Flags {
  display: flex;
  gap: 2rem;
  cursor: pointer;
  margin-left: 3rem;
}
.disabled {
  filter: grayscale(1);
  cursor: not-allowed;
}
.Logo p {
  margin: 0;
  font-size: 3rem;
  color: var(--cyan);
  cursor: pointer;
}

.Logo p span {
  /* color: var(--white); */
}
.Navbar nav {
  /* background: pink; */
  padding: 0 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Navbar nav ul {
  display: flex;
  margin: 0;
  padding: 0;
  gap: 2rem;
  justify-content: space-between;
}
.Navbar nav ul li {
  list-style: none;
}
.Navbar nav ul li a {
  /* color: var(--white); */
  text-decoration: none;
  /* background: pink; */
  padding: 1rem 2rem;
  text-align: center;
}
.Navbar nav ul li a:hover {
  color: var(--cyan);
}
.Icon {
  display: none;
  cursor: pointer;
}
.ButtonTop {
  position: fixed;
  bottom: 4rem;
  right: 4rem;
  padding: 2rem 2.4rem;
  background: var(--cyan);
  border-radius: 50%;
  cursor: pointer;
  color: white;
}
.ButtonTop i {
  font-size: 3rem;
}

.Theme {
  cursor: pointer;
}
.Theme:hover {
  color: var(--cyan);
}
.IconMenu {
  display: none;
}
@media screen and (max-width: 1000px) {
  .Icon {
    display: block;
  }
  .Navbar nav {
    position: fixed;
    top: 0;
    left: -90rem;
    width: 90%;
    height: 100vh;
    background: var(--dark);
    padding: 0 4rem;
    transition: left 500ms ease;
  }
  .Navbar nav ul {
    flex-direction: column;
    align-items: center;
  }
  .Navbar nav ul li {
    margin: 3rem;
  }

  .Navbar nav ul li a {
    /* background: red; */
    text-align: center;
    padding: 3rem 5rem;
    font-size: 4rem;
  }
  .Activo {
    left: 0 !important;
    position: relative;
  }
  .IconMenu {
    display: block;
    position: absolute;
    top: 2rem;
    right: 3rem;
    font-size: 3rem;
    cursor: pointer;
  }
}
