:root{
  --dark:#222831;
  --grey:#393e46;
  --cyan:#00adb5;
  --white:#eeeeee;
  --desktop:1200px;
  --laptop:1000px;
  --tablet:768px;
  --phone:480px;
}

html {
  /* 1rem = 10px */
  font-size: 62.5%;
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
  margin: 0;
  
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
     */
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  color: var(--white);
  font-size: 1.6rem;
  background: var(--dark);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  width: 100%;
  padding: 0 2rem;
  max-width: var(--desktop);
  margin: 0 auto;
  text-align: center;
}
.container h2{
  display: inline-block;
  font-size: 3rem;
  color: var(--cyan);
  border-bottom: .5rem solid var(--cyan);
  border-right: .5rem solid var(--cyan);
  padding:0 2rem 1rem .5rem;
  margin-bottom: 5rem;
}
.container h3{
  font-size: 2.3rem;
  color: var(--grey);
  margin: 0;
}
.fas{
  font-size: 2rem;
  /* background: red; */
}

/* BUTTON */
.button{
  padding: 1rem 2rem;
  background: var(--cyan);
  color: white;
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: bold;
  transition: color 500ms ease, background 500ms ease;
  margin: 2rem;
}
.button:hover{
  color: var(--grey);
  background: var(--white);
}
.sombra{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* FOOTER */
footer{
  margin-top: 2rem;
  background: var(--grey);
  padding: 3rem 0;
  text-align: center;
  color: white;
}