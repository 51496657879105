/* PROJECT */
.Project{
    width: 35rem;
    height: 20rem;
    /* background: purple;  */
    position: relative;
    filter: grayscale(1);
    color: white;
    
}
.Project:hover{
    filter: grayscale(0) !important;
}

.Project .Image img {
    width: 35rem;
    height:20rem;
    object-fit: fill;
    border-radius: 1rem;
    transition: filter 0.5s ease;
}

.Mask{
    /* display: none; */
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background: rgba(0, 0, 0, .6);
    transition: all 1s cubic-bezier(0.34, 1.56, 0.64, 1);
    display: flex;
    flex-direction: column;
}
.Mask h4{
    margin:0 0 .3rem 0;
}

.Content{
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}
.Project:hover .Content{
    transition: visibility .3s step-end, opacity 0.5s cubic-bezier(0.55, 0, 1, 0.45);
    visibility: visible;
    opacity: 1;
}
.Project:hover .Mask{
    height: 70%;
}
  
.Source{
    /* padding: 2rem; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.Source i{
    font-size: 3rem;
    color: var(--cyan);
    cursor: pointer;
}
.Source i:hover{
    color: white;
}
/* PORTAFOLIO */
.Projects{
    /* background: red; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
}