.Hero {
  /* background: red; */
  width: 100%;
  height: 100vh;
  position: relative;
}
.Hero img {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  object-fit: cover;
}
.Mask {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Image img {
  width: 21rem;
  height: 21rem;
  border-radius: 50%;
}
.Description {
  text-align: center;
  padding: 0 1.5rem;
}
.Description span {
  font-size: 3rem;
}
.Description h1 {
  font-size: 4rem;
  color: var(--cyan);
  margin: 0.5rem 0;
}
@media (max-width: 1000px) {
  .Mask {
    display: flex;
    flex-direction: column;
  }
  .Description {
    margin-top: 5rem;
  }
}
